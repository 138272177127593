import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import * as API from "@/game-apex/api.mjs";
import { GAME_MODES, LIFETIME_SEASON } from "@/game-apex/constants.mjs";
import getMatch from "@/game-apex/fetches/get-match.mjs";
import getMatchlist from "@/game-apex/fetches/get-matchlist.mjs";
import getProfile from "@/game-apex/fetches/get-profile.mjs";
import playerSeasonStats from "@/game-apex/fetches/player-season-stats.mjs";
import { ApexRankedStatsAggModel } from "@/game-apex/models/apex-ranked-stats-agg.mjs";
import { getCurrentSeason } from "@/game-apex/utils.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";

function fetchData(
  [profileIdRouteParam, seasonRouteParam, matchIdRouteParam]: FixedLengthArray<
    string,
    3
  >,
  searchParam: URLSearchParams,
) {
  const profileId = profileIdRouteParam || searchParam.get("profileId");
  const season = seasonRouteParam || searchParam.get("season");
  const matchId = matchIdRouteParam || searchParam.get("matchId");

  const seasonParam = season || "ALL";
  const seasonId = readState.apex.meta.seasons?.[seasonParam]?.id;

  const promises = [
    getProfile(profileId),
    ...(matchId ? [getMatch(matchId, seasonId)] : []),
  ] as const;

  return Promise.all(promises).then(([profile, match]) => {
    const mode = match?.["gameMode"] || (isRouteOverlay() ? "ALL" : null);

    if (mode && match?.playerMatchStats?.length) {
      getMatchlist({
        blitzId: profile.id,
        profileId,
        mode,
        seasonId,
        season: seasonParam,
      }).then((matches) => {
        if (Array.isArray(matches))
          for (let i = 0; i < 5; i += 1) {
            const match = matches[i];
            if (!match) continue;
            getMatch(match.apexId, match.season.id);
          }
      });
    }

    if (isRouteOverlay()) {
      const currentSeason = getCurrentSeason(readState.apex.meta.seasons);
      const rankedMode = GAME_MODES[mode]?.rankedMode;
      if (rankedMode && currentSeason)
        getData(
          API.getRankedStatsAgg({
            seasonId: currentSeason?.id,
            gameMode: rankedMode,
          }),
          ApexRankedStatsAggModel,
          ["apex", "rankedStatsAgg", currentSeason.apexId, rankedMode],
        );
      if (profile?.id) {
        playerSeasonStats({
          blitzId: profile.id,
          mode: rankedMode,
          seasonId:
            match?.season?.id || currentSeason?.id || LIFETIME_SEASON.id,
          season:
            match?.season?.apexSeasonNumber?.toString?.() ||
            currentSeason?.apexSeasonNumber ||
            LIFETIME_SEASON.apexSeasonNumber,
          profileId,
        });
      }
    }

    if (match && "playerMatchStats" in match && matchId) {
      for (const player of match.playerMatchStats) {
        if (!player.platformId) continue;
        getProfile(player.platformId)
          .then((profile) => {
            if (profile?.id && profile?.hoveredChampionApexId)
              playerSeasonStats({
                blitzId: profile.id,
                mode: GAME_MODES.RANKED.key,
                profileId: player.platformId,
                season: LIFETIME_SEASON.apexSeasonNumber,
                seasonId: LIFETIME_SEASON.id,
              });
          })
          .catch(() => {});
      }
    }
  });
}

export default fetchData;
